import "./styles/layout.scss";
import React, { useState, useRef, useEffect } from "react";
import { classNames } from "primereact/utils";
import AppTopbar from "./AppTopbar";
import AppSidebar from "./AppSidebar";
import AppFooter from "./AppFooter";
import UserInfo from "./UserInfo";
import { Button, Dialog, InputText } from "@/uiCore";
import { useGetAccessTokenStatus } from "./utils";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeToken } from "./api";
import { listToast } from "@/constants";
import { setToast } from "@/redux/features";

const Layout = (props) => {
  const sidebarRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [params, setParams] = useState({});
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const tokenStatus = useGetAccessTokenStatus({ ...params });
  const [hideSidebar, setHideSidebar] = useState(false);
  const [tokenString, setTokenString] = useState("");
  const [visible, setVisible] = useState(false);
  const onMenuToggle = () => {
    setHideSidebar(!hideSidebar);
  };
  useEffect(() => {
    if (hideSidebar) {
      localStorage.setItem("hideSidebar", true);
    } else {
      localStorage.setItem("hideSidebar", false);
    }
  }, [hideSidebar]);
  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth < 1300) {
        setHideSidebar(true);
      } else {
        setHideSidebar(false);
      }
    };
    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  useEffect(() => {
    if (tokenStatus?.is_valid !== undefined) {
      setVisibleDialog(!tokenStatus?.is_valid);
    }
  }, [tokenStatus, location?.pathname]);
  const linkClick = () => {
    window.open(
      "https://developers.facebook.com/tools/explorer?method=GET&path=me%3Ffields%3Daccounts&version=v19.0",
      "_blank"
    );
  };
  const handleCheckToken = async () => {
    setLoading(true);
    const res = await changeToken({ access_token: tokenString });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (res?.data?.is_valid) {
      dispatch(
        setToast({
          ...listToast[0],
          detail: `Cập nhật access_token thành công`,
        })
      );
      if (params && setParams) {
        setParams((pre) => {
          return { ...pre, render: !pre.render };
        });
      }
      setVisibleDialog(false);
    } else {
      dispatch(
        setToast({
          ...listToast[1],
          detail: res?.data?.error,
        })
      );
    }

    // setVisibleDialog(false);
  };
  const footerContent = (
    <div>
      <Button
        disabled={tokenString?.length <= 0}
        loading={loading}
        label="Check token"
        severity="info"
        icon="pi pi-check"
        onClick={() => handleCheckToken()}
      />
    </div>
  );
  return (
    <React.Fragment>
      {visible && (
        <UserInfo visible={visibleDialog} setVisible={setVisibleDialog} />
      )}
      <div className="layout-wrapper">
        <Dialog
          visible={visibleDialog}
          style={{ width: "50vw" }}
          onHide={() => {
            setVisibleDialog(false);
          }}
          footer={footerContent}
        >
          <p className="m-0 font-medium text-xl" style={{ color: "red" }}>
            Hệ thống báo lỗi: {tokenStatus?.error}
          </p>
          <div className="flex flex-column gap-2 mt-4">
            <span
              className="font-semibold text-lg cursor-pointer mb-2"
              style={{ textDecorationLine: "underline" }}
              onClick={() => {
                linkClick();
              }}
            >
              Lấy access_token tại đây!!!
            </span>
            <InputText
              id="username"
              aria-describedby="username-help"
              onChange={(e) => setTokenString(e.target.value)}
            />
            <small id="username-help">
              Nhập access_token lấy từ link trên để reset
            </small>
          </div>
        </Dialog>
        <AppTopbar onMenuToggle={onMenuToggle} setVisible={setVisible} />
        <div
          ref={sidebarRef}
          className={classNames("layout-sidebar", { "hide-menu": hideSidebar })}
        >
          <AppSidebar setVisible={setVisible} />
        </div>
        <div
          className={classNames("layout-main-container", {
            "hide-sidebar": hideSidebar,
          })}
        >
          <div className="layout-main">{props.children}</div>
          <AppFooter />
        </div>
        <div
          className={classNames({ "layout-mask": !hideSidebar })}
          onClick={onMenuToggle}
        ></div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
