import { getData, postData } from "@/lib/request";

/*--------------------------------------  GET   --------------------------------------------------*/
export const detailProject = (params) =>
  getData("web/project/detailProject", params);
export const getListLead = (params) => getData("/web/lead/getListLead", params);
export const getListAllProject = (params) =>
  getData("/web/project/getListAllProject", params);
export const getListDepartment = (params) =>
  getData("/web/lead/getListDepartment", params);
export const getListAllPage = (params) =>
  getData("/web/project/getListAllPage", params);
export const getListAllForm = (params) =>
  getData("/web/project/getListAllForm", params);
export const getListProjectForm = (params) =>
  getData("/web/project/getListProjectForm", params);
export const getListFormPage = (params) =>
  getData("web/form_page/getListFormPage", params);
export const getListAssignDate = (params) =>
  getData("web/form_page/getListAssignDate", params);
export const getListAssignSheet = (params) =>
  getData("web/form_page/getListAssignSheet", params);
export const listCampaign = (params) =>
  getData("web/project/listCampaign", params);
export const getCheckId = (params) =>
  getData("web/form_page/getCheckId", params);
export const getPbIdByDate = (params) =>
  getData("/web/assigndate/getPbIdByDate", params);
export const getTeam = (params) => getData("/web/assigndate/getTeam", params);
export const getListPage = (params) =>
  getData("/web/project/getListPage", params);
export const getListFormbyPage = (params) =>
  getData("/web/project/getListFormbyPage", params);
export const getListAssignCategory = (params) =>
  getData("/web/project/getListAssignCategory", params);
export const listAssignCategorySearch = (params) =>
  getData("/web/project/listAssignCategorySearch", params);
export const getListAssignPage = (params) =>
  getData("/web/project/getListAssignPage", params);
export const getListSheet = (params) =>
  getData("/web/project/getListSheet", params);
export const getCategoryDetail = (params) =>
  getData("/web/project/getCategoryDetail", params);
export const getListLeadbYCategory = (params) =>
  getData("/web/lead/getListLeadbYCategory", params);
export const listPrioritize = (params) =>
  getData("/web/project/listPrioritize", params);
export const getListDetailSheet = (params) =>
  getData("/web/project/getListDetailSheet", params);
export const listPbTour = (params) =>
  getData("/web/project/listPbTour", params);
export const getListFormZalo = (params) =>
  getData("/web/project/getListFormZalo", params);
export const getListLeadZaloForm2 = (params) =>
  getData("/web/project/getListLeadZaloForm2", params);
export const listSource = (params) => getData("/web/lead/listSource", params);
export const expListLeads = (params) => getData("dev/exportLead", params, true);
export const expListLeadperDaySearch = (params) =>
  getData("dev/expListLeadperDaySearch", params, true);
export const getListTeambyProject = (params) =>
  getData("/web/assigndate/getListTeambyProject", params);
export const getListDxc = (params) =>
  getData("/web/project/getListDxc", params);
export const getListFormZaloAssign = (params) =>
  getData("/web/project/getListFormZaloAssign", params);
export const getUserLogToken = (params) =>
  getData("/web/auth/getUserLogToken", params);

/*--------------------------------------  POST   --------------------------------------------------*/

export const importLead = (params) => postData("dev/importLead", params, true);
export const importLeadZalo = (params) =>
  postData("dev/importLeadZalo", params, true);
export const UpdateInsertLeads = (params) =>
  postData("/web/lead/UpdateInsertLeads", params);
export const phanBo = (params) => postData("/web/lead/phanBo", params);
export const phanboDuan = (params) => postData("/web/lead/phanboDuan", params);
export const updatePageList = (params) =>
  postData("/web/project/updatePageList", params);
export const cronHand = (params) => postData("/web/project/cronHand", params);
export const prioritizeProject = (params) =>
  postData("/web/project/prioritizeProject", params);
export const deletePrioritizeProject = (params) =>
  postData("/web/project/deletePrioritizeProject", params);
// export const sendBot = (params) => postData("/web/project/sendBot", params);
export const updateActivePage = (params) =>
  postData("/web/project/updateActivePage", params);
export const updateActiveZaloForm = (params) =>
  postData("/web/project/updateActiveZaloForm", params);
export const updateActiveSheet = (params) =>
  postData("/web/project/updateActiveSheet", params);
export const updateActiveDetailSheet = (params) =>
  postData("/web/project/updateActiveDetailSheet", params);
export const addLeadHand = (params) =>
  postData("/web/lead/addLeadHand", params);
export const checkWebsite = (params) => postData("/dev/checkWebsite", params);
export const updateSheet = (params) =>
  postData("/web/project/updateSheet", params);
export const insertSheet = (params) =>
  postData("/web/project/insertSheet", params);
export const insertNewSheet = (params) =>
  postData("/web/project/insertNewSheet", params);
export const updateSheetDetail = (params) =>
  postData("/web/project/updateSheetDetail", params);
export const updateDetailSheet = (params) =>
  postData("/web/project/updateDetailSheet", params);
export const removeSheet = (params) =>
  postData("/web/project/removeSheet", params);
export const updateDxcHandle = (params) =>
  postData("/web/project/updateDxcHandle", params);
export const refreshLead = (params) =>
  postData("/web/project/refreshLead", params);
export const autoZalo = (params) => postData("/dev/autoZalo", params);
export const checkIdSheet = (params) => postData("/dev/checkIdSheet", params);
export const addLeadMiss = (params) =>
  postData("/web/project/addLeadMiss", params);
export const updateCookie = (params) => postData("/send-message", params);
export const updatePage = (params) =>
  postData("/web/project/updatePage", params);
export const updatedata = (params) => postData("/web/project/syncData", params);
export const updateProjectCampaign = (params) =>
  postData("/web/project/updateProjectCampaign", params);
export const updateProjectZalo = (params) =>
  postData("/web/project/updateProjectZalo", params);
export const assignDateSingle = (params) =>
  postData("/web/form_page/assignDateSingle", params);
export const assignDateSingleNew = (params) =>
  postData("/web/form_page/assignDateSingleNew", params);
export const assignDateSheet = (params) =>
  postData("/web/form_page/assignDateSheet", params);
export const assignDateSheetNew = (params) =>
  postData("/web/form_page/assignDateSheetNew", params);
export const updateFormZalo = (params) =>
  postData("/web/project/updateFormZalo", params);
export const updateProjectForm = (params) =>
  postData("/web/project/updateProjectForm", params);
export const changeToken = (params) =>
  postData("/web/project/changeToken", params);
// export const deleteLead = (params) => postData("/web/lead/deleteLead", params);
// export const SetUpLead = (params) => postData("/web/lead/SetUpLead", params);
// export const UpdateListRemain1 = (params) =>
//   postData("/web/lead/UpdateListRemain1", params);
// export const UpdateListRemain2 = (params) =>
//   postData("/web/lead/UpdateListRemain2", params);
